import React, { Component } from 'react';
import classNames from 'classnames';

import { compact, isEmpty } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography
} from '@material-ui/core';

import ProgressPanel from '../ProgressPanel';
import FormHeader from '../FormHeader';
import InputField from '../InputField';
import SelectField from '../SelectField';
import { globalStyles } from '../../styles/style-constants';

import { withRouter } from 'react-router-dom';

import Api from '../../modules/serverless-api';

class InterestedForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      totalSteps: 9,
      firstName: '',
      lastName: '',
      email: '',
      zipCode: '',
      age: '',
      gender: '',
      diagnosedConditions: {},
      ethnicities: {},
      willingToTravel: '',
      otherConditions: '',
      militaryExMilitary: '',
      interestedTrials: {},
      showPatientInterestedForm: false,
      availableTrials: [{
        name: 'Psilocybin in patients with cancer and coping with depression',
        description: ''
      }, {
        name: 'MDMA assisted Psychotherapy for Patients with Treatment Resistant PTSD',
        description: ''
      }]
    };
  }

  onNext() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  onPrevious() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  async onComplete() {
    try {
      await Api.submitInterestedForm({
        'SUBMIT DATE': new Date().toString(),
        'FIRST NAME': this.state.firstName,
        'LAST NAME': this.state.lastName,
        'EMAIL ADDRESS': this.state.email,
        'ZIP CODE': this.state.zipCode,
        'DOB': this.state.age,
        'GENDER': this.state.gender,
        'ETHNICITY': `${compact(Object.keys(this.state.ethnicities)).join(',')}`,
        'TRIAL INTEREST': Object.keys( this.state.interestedTrials).join(','),
        'WILLING TO TRAVEL 50 MILES': this.state.willingToTravel,
        'DIAGNOSED CONDITIONS': `${compact(Object.keys(this.state.diagnosedConditions).concat([this.state.otherConditions])).join(',')} military: ${this.state.militaryExMilitary} `
      });

      const { history } = this.props;
      history.push('/interested/complete');
    } catch(e) {
      alert('There was an error submitting the form. Please try again or contact the administrator.');
    }
  }

  handleDiagnosedCondition = value => e => {
    const { diagnosedConditions } = this.state;

    if (diagnosedConditions[value]) {
      delete diagnosedConditions[value];
      if (value === 'ptsd') {
        this.setState({ militaryExMilitary: null });
      }
    } else {
      diagnosedConditions[value] = true;
    }

    this.setState({ diagnosedConditions });
  }

  handleInterestedTrial = value => e => {
    const { interestedTrials } = this.state;

    if (interestedTrials[value]) {
      delete interestedTrials[value];
    } else {
      interestedTrials[value] = true;
    }

    this.setState({ interestedTrials });
  }

  handleEthnicity = value => e => {
    const { ethnicities } = this.state;

    if (ethnicities[value]) {
      delete ethnicities[value];
    } else {
      ethnicities[value] = true;
    }

    this.setState({ ethnicities });
  }

  render() {
    const { classes } = this.props;
    const { 
      
      currentStep, 
      totalSteps, 
      firstName, 
      lastName, 
      email, 
      zipCode, 
      age, 
      gender, 
      diagnosedConditions, 
      ethnicities, 
      willingToTravel, 
      availableTrials, 
      interestedTrials, 
      otherConditions,
      militaryExMilitary
    } = this.state;

    return (
      <div className={classes.patternWrapper}>
        <Grid className={classNames(classes.widthWrapper, classes.panel)} direction="column" justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center" className={classes.content}> 
              <Grid item>
                { currentStep === 0 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>Tell us about yourself</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What's your name?" />
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} direction="row">
                        <Grid item className={classes.nameInput}>
                          <InputField collapsed={true} labelText={"First Name"}
                            onChange={e => this.setState({ firstName: e.target.value })}
                            inputProps={{
                              value: firstName
                            }}
                          />
                        </Grid>
                        <Grid item className={classes.nameInput}>
                          <InputField collapsed={true} labelText={"Last Name"} 
                            onChange={e => this.setState({ lastName: e.target.value })}
                            inputProps={{
                              value: lastName
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 1 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>2/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What’s your email address?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"Your email address"}
                            onChange={e => this.setState({ email: e.target.value })}
                            inputProps={{
                              value: email
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 2 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>3/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="Where do you live?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"Your zipcode is enough"}
                            onChange={e => this.setState({ zipCode: e.target.value })}
                            inputProps={{
                              value: zipCode
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 3 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>4/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What is your date of birth?" />
                    </Grid>
                    <Grid item className={classes.emailInput}>
                      <Grid container direction="row">
                        <Grid item className={classes.emailInput}>
                          <InputField collapsed={true} labelText={"MM/DD/YYYY"}
                            onChange={e => this.setState({ age: e.target.value })}
                            inputProps={{
                              value: age
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 4 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>5/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What is your gender?" />
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'female'} onClick={e => this.setState({ gender: 'female' })} labelText={"Female"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'male'} onClick={e => this.setState({ gender: 'male' })} labelText={"Male"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'nonbinary'} onClick={e => this.setState({ gender: 'nonbinary' })} labelText={"Non-binary"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'other'} onClick={e => this.setState({ gender: 'other' })} labelText={"Other"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={gender === 'not-say'} onClick={e => this.setState({ gender: 'not-say' })} labelText={"Prefer not to say"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 5 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>6/9</Typography>
                    </Grid>
                    <Grid item>
                      <FormHeader text="What is your ethnicity?" />
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['white'] != null} onClick={this.handleEthnicity('white')} labelText={"White or Caucasian"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['asian'] != null} onClick={this.handleEthnicity('asian')} labelText={"Asian"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['black'] != null} onClick={this.handleEthnicity('black')} labelText={"Black, African American"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['pacificislander'] != null} onClick={this.handleEthnicity('pacificislander')} labelText={"Pacific Islander or Hawaiian"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['americanindian'] != null} onClick={this.handleEthnicity('americanindian')} labelText={"American Indian or Alaska Native"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={ethnicities['latino'] != null} onClick={this.handleEthnicity('latino')} labelText={"Latino or Hispanic"} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 6 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>7/9</Typography>
                    </Grid>
                    <Grid item align="center">
                      <FormHeader text="Are you currently diagnosed with any of the following conditions?" />
                      <br/>
                      <br/>
                      <Typography>Select all that apply.</Typography>
                      <br/>
                      <br/>
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['cancer'] != null} onClick={this.handleDiagnosedCondition('cancer')} labelText={"Cancer"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['addiction'] != null} onClick={this.handleDiagnosedCondition('addiction')} labelText={"Addiction"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['anxiety'] != null} onClick={this.handleDiagnosedCondition('anxiety')} labelText={"Anxiety"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['trd'] != null} onClick={this.handleDiagnosedCondition('trd')} labelText={"Treatment Resistant Depression (TRD)"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['depression'] != null} onClick={this.handleDiagnosedCondition('depression')} labelText={"Depression"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={diagnosedConditions['eating-disorder'] != null} onClick={this.handleDiagnosedCondition('eating-disorder')} labelText={"Eating Disorder"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField 
                            type="checkbox" 
                            checked={diagnosedConditions['ptsd'] != null || !isEmpty(militaryExMilitary)} 
                            onClick={this.handleDiagnosedCondition('ptsd')} 
                            labelText={"PTSD"}
                            selectLabel={"Are you in the military or ex-military?"}
                            selectOptions={['Yes', 'No']}
                            onSelectInputChange={e => this.setState({ militaryExMilitary: e.target.value })}
                            selectValue={militaryExMilitary}
                          />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField 
                            type="checkbox" 
                            checked={diagnosedConditions['other'] != null || !isEmpty(otherConditions)} 
                            onClick={this.handleDiagnosedCondition('other')} 
                            onInputChange={e => this.setState({ otherConditions: e.target.value })} 
                            inputValue={otherConditions} 
                            labelPlaceholder={"Enter your info here"} 
                            labelText={"Other:"} 
                          />
                        </Grid>
                        <Grid item className={classes.genderInput}>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 7 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>8/9</Typography>
                    </Grid>
                    <Grid item align="center">
                      <FormHeader text="Are you interested in a specific trial?" />
                      <br/>
                      <br/>
                      <Typography>Select all that apply.</Typography>
                      <br/>
                      <br/>
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        { availableTrials.map(trial => {
                          return (
                            <Grid item className={classes.genderInput}>
                              <SelectField type="checkbox" checked={interestedTrials[trial.name] != null} onClick={this.handleInterestedTrial(trial.name)} labelText={trial.name} description={trial.description} />
                            </Grid>
                          );
                        }) }
                        <Grid item className={classes.genderInput}>
                          <SelectField type="checkbox" checked={interestedTrials[`I'm not sure`] != null} onClick={this.handleInterestedTrial(`I'm not sure`)} labelText={"I'm not sure"} description={"Email me when a trial matches my criteria."} />
                        </Grid>

                        { availableTrials.length % 2 === 0 && (
                          <Grid item className={classes.genderInput}>

                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                { currentStep === 8 && (
                  <Grid direction="column" container alignItems="center" justify="center">
                    <Grid item>
                      <Typography className={classes.label}>9/9</Typography>
                    </Grid>
                    <Grid item align="center">
                      <FormHeader text="Are you willing to travel? (> 50mi)" />
                    </Grid>
                    <Grid item>
                      <Grid container justify="center" spacing={3} direction="row">
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={willingToTravel === 'yes'} onClick={e => this.setState({ willingToTravel: 'yes' })} labelText={"Yes"} />
                        </Grid>
                        <Grid item className={classes.genderInput}>
                          <SelectField type="radio" checked={willingToTravel === 'no'} onClick={e => this.setState({ willingToTravel: 'no' })} labelText={"No"} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

        <Grid item xs={12}>
            <ProgressPanel
              isFirstStep={currentStep === 0}
              onNext={this.onNext.bind(this)}
              onPrevious={this.onPrevious.bind(this)}
              submitLabel={"Submit"}
              onComplete={this.onComplete.bind(this)}
              percentComplete={Math.round(currentStep / (totalSteps - 1) * 100)} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  panel: {
    padding: '20px',
    borderRadius: '8px'
  },
  emailInput: {
    width: 624
  },
  genderInput: {
    width: 520
  },
  nameInput: {
    width: 233
  },
  label: {
    marginBottom: 20,
    ...globalStyles.label1
  },
  content: {
    minHeight: 'calc(100vh - 120px)'
  },
  patternWrapper: {
    height: '100vh',
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme)
});

export default withRouter(withStyles(styles)(InterestedForm));