import {
  Grid,
  Typography,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { globalColors } from '../styles/style-constants';

import InputField from './InputField';

function SelectField({ 
  classes, 
  type = 'radio', 
  labelText, 
  description, 
  selected, 
  helperText = null, 
  onClick, 
  inputValue,
  onInputChange,
  ...rest 
}) {
  return (
    <Grid className={classes.panel} container direction="row" justify="flex-start" onClick={onClick}>
      <Grid item style={{ marginTop: -4 }}>
        { type === 'radio' && (
          <Radio
            {...rest}
          />
        )}
        { type === 'checkbox' && (
          <Checkbox
            {...rest}
          />
        )}
      </Grid>
      <Grid item xs>
        <Grid container direction="column" spacing={1}>
          <Grid item> 
            <Typography className={classes.label}>{labelText}</Typography>
            { description && (
              <Typography className={classes.description}>{description}</Typography>
            )}
          </Grid>

          { onInputChange && (
            <Grid item xs>
              <InputField collapsed={true}
                onChange={onInputChange}
                placeholder={rest.labelPlaceholder}
                onClick={e => e.stopPropagation()}
                inputProps={{
                  value: inputValue
                }}
              />
            </Grid>
          )}

          { rest.onSelectInputChange && (
            <Grid item xs>
              <Typography>{rest.selectLabel}</Typography>
              <RadioGroup row onChange={rest.onSelectInputChange} value={rest.selectValue}>
                { rest.selectOptions.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={option} />
                ))}
              </RadioGroup>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  panel: {
    cursor: 'pointer',
    padding: '20px',
    background: theme.globalColors.white,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px'
  },
  label: {
    marginTop: 8,
    color: globalColors.blue
  },
  description: {
    color: globalColors.darkGrey,
    marginTop: 4,
    fontSize: 12,
    marginBottom: 8
  }
});

export default withStyles(styles)(SelectField);
